<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("marks.title") }}
          </template>
          <template v-slot:toolbar>
            
          </template>
          <template v-slot:body>
            <div>
              <b-form>
                <div class="row">
                  <div class="col-4">
                    <b-form-group>
                      <template v-slot:label>
                        {{ $t("studentGroups.list.year") }}
                      </template>
                      <b-form-select
                          v-model="filter.educationYear"
                          :options="educationYears"
                          @change="reloadGroups"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group>
                      <template v-slot:label>
                        {{ $t("studentGroups.list.level") }}
                      </template>
                      <b-form-select
                          v-model="filter.level"
                          :options="levels"
                          @change="reloadGroups"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group>
                      <template v-slot:label>
                        Класс
                      </template>
                      <b-form-select
                          v-model="filter.groupId"
                          :options="groups"
                          @change="reloadGroupSubjects"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group>
                      <template v-slot:label>
                        Предмет
                      </template>
                      <b-form-select
                          v-model="groupSubjectId"
                          :options="groupSubjects"
                          @change="reloadData"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
            <div>
              <SubjectInfo :subject="subjectInfo"></SubjectInfo>
            </div>

            <b-tabs pills card id="">
              <b-tab>
                <template v-slot:title>
                  I четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="1" :show-edit-button="false" ref="marksGeneralGroupSubjectQ1"></MarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  II четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="2" :show-edit-button="false" ref="marksGeneralGroupSubjectQ2"></MarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  III четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="3" :show-edit-button="false" ref="marksGeneralGroupSubjectQ3"></MarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  IV четверть
                </template>
                <MarksByQuarter :group-subject-id="groupSubjectId" :quarter="4" :show-edit-button="false" ref="marksGeneralGroupSubjectQ4"></MarksByQuarter>
              </b-tab>   
            </b-tabs>
            
            
            
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import SubjectInfo from "./partials/SubjectInfo.vue";
import MarksByQuarter from "./partials/MarksByQuarter.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
export default {
  name: "MarksGeneral",
  components: {
    MarksByQuarter,
    SubjectInfo,
    KTPortlet
  },
  data() {
    return {
      groupSubjectId: this.$route.params.id + "",
      filter:{
        educationYear: DictionariesService.currentYear()
      },
      subjectInfo: {
        subjectName: '',
        groupName: '',
        teacherName: '',
      },
      groups: [],
      groupSubjects: [],
      levels: DictionariesService.levelOptions(),
      educationYears: DictionariesService.educationYearOptions(),
      shifts: DictionariesService.shiftOptions(),
      sexOptions:DictionariesService.sexOptions(),
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    
    reloadData() {
      let $this = this;

      ApiService.querySecured("groupSubjects/info", {
        params: {id: this.groupSubjectId}
      })
          .then(({data}) => {
            $this.subjectInfo = data;
            $this.$refs['marksGeneralGroupSubjectQ1'].reloadData();
            $this.$refs['marksGeneralGroupSubjectQ2'].reloadData();
            $this.$refs['marksGeneralGroupSubjectQ3'].reloadData();
            $this.$refs['marksGeneralGroupSubjectQ4'].reloadData();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    reloadGroups(){
      ApiService.querySecured("dictionaries/groupsForFilter",
          {params: {educationYear: this.filter.educationYear, level: this.filter.level}})
          .then(({data}) => {
            data.dictionary.unshift({ value: 0, text: '' });
            this.groups = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    reloadGroupSubjects(){
      ApiService.querySecured("dictionaries/getGroupSubjectsForGroupForFilter",
          {params: {groupId: this.filter.groupId}})
          .then(({data}) => {
            
            this.groupSubjects = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    }
  }
};
</script>
