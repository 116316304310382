<template>
  <div class="view">
    <div style="overflow-x: auto" class="table-container">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th class="text-left sticky-col first-column"></th>
          <th class="text-left sticky-col second-column"><i class="fa fa-calendar"></i></th>
          <th v-for="item in formData.lessons" :key="item.id">
            {{ item.subjectDateString }}
          </th>
          <th rowspan="4">
            СР
          </th>
          <th rowspan="4">
            ЧТ
          </th> 
          <th rowspan="4" v-if="quarter === 4">
            Год.
          </th>  
          <th rowspan="4" v-if="quarter === 4">
            Экз.
          </th>        
          <th rowspan="4" v-if="quarter === 4">
            Итог.
          </th>
        </tr>
        <tr>
          <th class="text-left sticky-col first-column"></th>
          <th class="text-left sticky-col second-column"><i class="fa fa-clock"></i></th>
          <th v-for="item in formData.lessons" :key="item.id">
            {{ item.lessonTimeString }}
          </th>
        </tr>
        <tr>
          <th class="text-left sticky-col first-column"></th>
          <th class="text-left sticky-col second-column">{{ $t("marks.lessonContent") }}</th>
          <th v-for="item in formData.lessons" :key="item.id">
            <div class="th-data">{{ item.lessonContent }}</div>
          </th>
        </tr>
        <tr>
          <th class="text-left sticky-col first-column"></th>
          <th class="text-left sticky-col second-column">{{ $t("marks.homeWork") }}</th>
          <th v-for="item in formData.lessons" :key="item.id">
            <div class="th-data">{{ item.homework }}</div>
          </th>
        </tr>
        <tr>
          <th class="text-left sticky-col first-column">#</th>
          <th class="text-left sticky-col second-column">
            {{ $t("marks.list.fio") }}
          </th>
          <th v-for="item in formData.lessons" :key="item.id"

              width="50px"
              style="vertical-align: top"
          >
            <a class="btn btn-primary btn-sm btn-icon" :href="'#/lesson/marks/' + item.id"
               v-if="isUserHasAccessToEdit && showEditButton && item.isEditable"
               :class="{'btn-danger':item.markAsNotFilled }">
              <i class="fa fa-pen"></i>
            </a>
          </th>
          <th></th>
          <th>
            <router-link
                v-if="isUserHasAccessToEdit && showEditButton && isQuarterMarksEditable"
                         :to="{ name: 'quarterMarksForSubject', params: { groupSubjectId: this.groupSubjectId, quarter: this.quarter}}"
                         class="btn btn-primary btn-sm btn-icon">
              <i class="fa fa-pen"></i>
            </router-link>
          </th>
          <th v-if="quarter === 4">
            <router-link
                v-if="isUserHasAccessToEdit && showEditButton && isQuarterMarksEditable"
                         :to="{ name: 'quarterMarksForSubject', params: { groupSubjectId: this.groupSubjectId, quarter: 5}}"
                         class="btn btn-primary btn-sm btn-icon">
              <i class="fa fa-pen"></i>
            </router-link>
          </th>  
          <th v-if="quarter === 4">
            <router-link
                v-if="isUserHasAccessToEdit && showEditButton && isQuarterMarksEditable"
                         :to="{ name: 'quarterMarksForSubject', params: { groupSubjectId: this.groupSubjectId, quarter: 6}}"
                         class="btn btn-primary btn-sm btn-icon">
              <i class="fa fa-pen"></i>
            </router-link>
          </th>  
          <th v-if="quarter === 4">
            <router-link
                v-if="isUserHasAccessToEdit && showEditButton && isQuarterMarksEditable"
                         :to="{ name: 'quarterMarksForSubject', params: { groupSubjectId: this.groupSubjectId, quarter: 7}}"
                         class="btn btn-primary btn-sm btn-icon">
              <i class="fa fa-pen"></i>
            </router-link>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(student, index) in formData.students"
            :key="student.studentId"
        >
          <td class="sticky-col first-column">{{ index + 1 }}</td>
          <td class="sticky-col second-column">{{ student.studentName }}</td>
          <td
              v-for="lesson in formData.lessons"
              :key="lesson.id"
              :title="lesson.subjectDateString"
          >
            {{ displayMark(marks[student.studentId][lesson.id]) }}

          </td>
          <td
          >
            {{ displayAverageMark(averageMarks[student.studentId]) }}
          </td>
          <td>
            {{ displayQuarterMark(quarterMarks[student.studentId]) }}
          </td>   
          <td v-if="quarter === 4">
            {{ displayQuarterMark(annualMarks[student.studentId]) }}
          </td> 
          <td v-if="quarter === 4">
            {{ displayQuarterMark(examinationMarks[student.studentId]) }}
          </td>    
          <td v-if="quarter === 4">
            {{ displayQuarterMark(finalMarks[student.studentId]) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style>
</style>
<script>

import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "MarksByQuarter",
  components: {},
  props: {
    groupSubjectId: String,
    quarter: Number,
    showEditButton: Boolean,
  },
  data() {
    return {
      marks: {},
      averageMarks: {},
      quarterMarks: {},
      annualMarks: {},
      examinationMarks: {},
      finalMarks: {},
      
      formData: {},
      students: [],
      isQuarterMarksEditable: false,
      isUserHasAccessToEdit: false,
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      let $this = this;
      ApiService.querySecured("marks/getMarksByQuarter", {
        params: {groupSubjectId: this.groupSubjectId, quarter: this.quarter}
      })
          .then(({data}) => {
            $this.marks = {};
            $this.averageMarks = {};
            $this.isQuarterMarksEditable = data.isQuarterMarksEditable;

            for (let i in data.students) {
              let student = data.students[i];

              $this.marks[student.studentId] = {};
              $this.averageMarks[student.studentId] = "";
              $this.quarterMarks[student.studentId] = "";
              
              if($this.quarter === 4){
                $this.annualMarks[student.studentId] = "";
                $this.examinationMarks[student.studentId] = "";
                $this.finalMarks[student.studentId] = "";
              }

              for (let ii in data.lessons) {
                let lesson = data.lessons[ii];

                $this.marks[student.studentId][lesson.id] = {};
              }
            }

            let marks = data.marks;
            for (let i in marks) {
              let item = marks[i];

              if (typeof $this.marks[item.studentId] != 'undefined') {

                $this.marks[item.studentId][item.lessonId] = item.mark;
              }
            }

            let averageMarks = data.averageMarks;
            for (let i in averageMarks) {
              let item = averageMarks[i];

              if (typeof $this.averageMarks[item.studentId] != 'undefined') {

                $this.averageMarks[item.studentId] = item.markString;
              }
            }

            function processQuarterMarks(qMarksData, qMarksArray){
              for (let i in qMarksData) {
                let item = qMarksData[i];

                if (typeof qMarksArray[item.studentId] != 'undefined') {

                  qMarksArray[item.studentId] = {
                    mark: item.mark,
                    mark2: item.mark2,
                  };
                }
              }
            }
            
            processQuarterMarks(data.quarterMarks, $this.quarterMarks);
            
            if(data.annualMarks !== null){
              processQuarterMarks(data.annualMarks, $this.annualMarks);
            }     
            
            if(data.examinationMarks !== null){
              processQuarterMarks(data.examinationMarks, $this.examinationMarks);
            }
            
            if(data.finalMarks !== null){
              processQuarterMarks(data.finalMarks, $this.finalMarks);
            }

            $this.formData = data;
          })
          .catch(({response}) => {
            console.log(response);
          });

      ApiService.querySecured("access/getUserAccessToMarks", {
        params: {subjectId: this.groupSubjectId}
      })
          .then(({data}) => {
            this.isUserHasAccessToEdit = data.accessEditing;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    displayMark(mark) {
      return DictionariesService.displayMark(mark);
    },
    displayAverageMark(mark) {
      return mark;
    },
    displayQuarterMark(mark) {
      return DictionariesService.displayQuarterMark(mark.mark) + (mark.mark2 != null ? "/" + DictionariesService.displayQuarterMark(mark.mark2) : "");
    },
  }
};
</script>
